@import "config/base";

.zinnia {
  line-height: 1.5;
  font-family: $font-family;
  color: black;
  background: none;
  font-size: 10pt;

  a, a:link, a:visited, a:focus, a:active, a:hover {
    text-decoration: none;
    color: dodgerblue;
  }
}

#sidebar, .top-navigation, .comment-form {
  display: none;
}

#header {
  h1 {
    margin-bottom: 0;
    a {
      font-size: 2em;
      font-weight: bold;
    }
  }
}
