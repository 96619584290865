// Grid constants
$multi-columns-width    : 85em;
$single-column-width    : 60em;
$gutter-width           : 1em;

// Fonts constants
$font-size              : 12px;
$font-family            : unquote("Arial, Helvetica, sans-serif");
$font-family-fixed      : unquote("andale mono, lucida console, monospace");

// UI Colors
$info-color             : #2ba6cb;
$error-color            : #da0000;
$success-color          : #7fae00;
